import React, {ReactNode, useState} from 'react';
import ReactDOM from 'react-dom/client';
import ExportedApp from 'src/App';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {AuthGuard, IDPType, useAnalyticsHandler} from '@fce-bees/fce-common-components';
import {AuthConfig} from '@fce-bees/fce-common-components/src/components/auth-cookie/cookie-auth-service';
import {getMarketLanguage} from './utils/get-market-language';

const clientId = import.meta.env.VITE_CONTAINER_CLIENT_ID;
const analyticsScriptSrc = import.meta.env.VITE_AA_SCRIPT_SRC;
const isAuthDisabled = import.meta.env.VITE_AUTH_MODE === 'ignore';
const imsBaseUrl = import.meta.env.VITE_FINAPP_IMS_BASE_URL;
const applicationId = import.meta.env.VITE_CONTAINER_APP_ID;

const {marketLanguage} = getMarketLanguage();

const aadTenantUrl = `${import.meta.env.VITE_AAD_BASE_URL}${import.meta.env.VITE_AAD_POLICY_ID}${marketLanguage}${
    import.meta.env.VITE_ADD_OAUTH_PATH
}`;

const authConfig: AuthConfig = {
    baseAuthorizationURL: aadTenantUrl,
    idpClientId: clientId,
    redirectURI: imsBaseUrl + import.meta.env.VITE_REDIRECT_URI_PATH,
    responseMode: 'form_post',
    resource: applicationId,
};

const AnalyticsProvider = ({children}: {children: ReactNode}) => {
    const [isAnalyticsLoaded, setIsAnalyticsLoaded] = useState(!analyticsScriptSrc);

    useAnalyticsHandler({
        scriptSrc: analyticsScriptSrc,
        onAnalyticsLoaded: () => setTimeout(() => setIsAnalyticsLoaded(true)),
    });

    return !isAnalyticsLoaded ? null : children;
};

const CookieAuthGuard = ({children}: {children: ReactNode}) => (
    <AuthGuard
        authType="COOKIE"
        errorRoute="/error"
        imsBaseUrl={imsBaseUrl}
        applicationId={applicationId}
        authConfig={authConfig}
        cookieDomain=".ford.com"
        idpType={IDPType.aad}
        market={marketLanguage}
        processId=""
    >
        {children}
    </AuthGuard>
);

const AuthGuarded = ({children}: {children: ReactNode}) => {
    return isAuthDisabled ? children : <CookieAuthGuard>{children}</CookieAuthGuard>;
};

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <AnalyticsProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<AuthGuarded>{<ExportedApp />}</AuthGuarded>} />
                </Routes>
            </BrowserRouter>
        </AnalyticsProvider>
    </React.StrictMode>
);
